import React from 'react';
import { FiInstagram } from '@react-icons/all-files/fi/FiInstagram';
import Layout from '../../components/Layout';

const Contact = () => (
  <Layout>
    <section className='contact'>
      <ul>
        <li>
          <p>Email</p>
        </li>
        <li>
          <a href='mailto: sarahysolheim@gmail.com'>sarahysolheim@gmail.com</a>
        </li>
        <li>
          <li>
            <p>Instagram</p>
          </li>
          <a target='_blank' href='https://www.instagram.com/sarahsolame/'>
            @sarahsolame
          </a>
        </li>
        <li>
          <li>
            <p>Instagram (blåskjell)</p>
          </li>
          <a target='_blank' href='https://www.instagram.com/bla_skjell/'>
            @bla_skjell
          </a>
        </li>
      </ul>
    </section>
  </Layout>
);

export default Contact;
